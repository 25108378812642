import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from '@pages/Dashboard/Dashboard'
import Login from '@pages/Login/Login'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/' element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}
