/** @format */

import { adminLoginAPI } from '@api/login'
import { getUserListAPI, modifyUserListAPI } from '@api/admin'
import { useState } from 'react'
import { AdminLoginInterface } from '@/types/admin'
import { useQuery, useMutation } from 'react-query'
import { GridRowId } from '@mui/x-data-grid'

export const UseAdminLoginManager = () => {
  const login = useMutation(
    (loginForm: AdminLoginInterface) => {
      const result = adminLoginAPI(loginForm)
      return result
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          localStorage.setItem('ACCESS_TOKEN', res.data.ACCESS_TOKEN)
          localStorage.setItem('REFRESH_TOKEN', res.data.REFRESH_TOKEN)
          window.location.replace('/dashboard')
        }
      },
      onError: ({ response: res }) => {
        if (res.status === 401) alert('계정 정보가 올바르지 않습니다.')
        else alert('로그인에 실패했습니다.')
      },
    },
  )

  return { login }
}

export const UseAdminListManager = () => {
  const [userList, setUserList] = useState([])

  useQuery(['getUserListQuery'], getUserListAPI, {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      return setUserList(res?.data)
    },
    onError: ({ response: res }) => {
      if (res.status === 401) {
        alert('로그인이 필요합니다.')
        window.location.replace('/')
      } else alert('에러가 발생했습니다.')
    },
  })

  const modifyCheck = useMutation(
    (list: GridRowId[]) => {
      const result = modifyUserListAPI(list)
      return result
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          window.location.reload()
        }
      },
      onError: (res) => {
        alert('에러가 발생했습니다.')
      },
    },
  )

  return { modifyCheck, userList }
}
