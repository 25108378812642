import { UseAdminListManager } from '@hooks/use-admin'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { Button } from '@mui/material'
import React, { useState } from 'react'

export default function Dashboard() {
  const { modifyCheck, userList } = UseAdminListManager()
  // console.table('userList', userList)
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([])
  const columns: GridColDef[] = [
    {
      field: 'checked',
      headerName: '확인 여부',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.checked ? 'O' : 'X'
      },
    },
    {
      field: 'id',
      headerName: '번호',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: '이름',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'birthDate',
      headerName: '생년월일',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'phoneNumber',
      headerName: '전화번호',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'categoryName',
      headerName: '업종(구분)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },

    {
      field: 'registrationDate',
      headerName: '등록일자',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'recommendedPerson',
      headerName: '추천인',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
    },
  ]

  return (
    <div style={{ padding: 30 }}>
      <DataGrid
        className='table-click-row'
        rowHeight={32}
        rows={userList}
        columns={columns}
        getRowId={(row) => row.id}
        autoHeight
        checkboxSelection={true}
        onRowClick={(param) => console.log(param)}
        onRowSelectionModelChange={(
          newRowSelectionModel: GridRowSelectionModel,
        ) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '20px',
        }}
      >
        <Button
          onClick={() => modifyCheck.mutate(rowSelectionModel)}
          variant='contained'
        >
          저장하기
        </Button>
      </div>
    </div>
  )
}
