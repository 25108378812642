/** @format */

import axios from 'axios'
import { AdminLoginInterface } from '@/types/admin'
import { GridRowId } from '@mui/x-data-grid'

// NOTE(seulgi): 쿠키 주고 받으려면 필요한 헤더 설정
// axios.defaults.withCredentials = true
const token = localStorage.getItem('ACCESS_TOKEN')
const refreshToken = localStorage.getItem('REFRESH_TOKEN')

export const getUserListAPI = () => {
  return axios.get(`${process.env.REACT_APP_FETCH_URL}/client`, {
    headers: { Authorization: token },
  })
}

export const modifyUserListAPI = (list: GridRowId[]) => {
  return axios.patch(
    `${process.env.REACT_APP_FETCH_URL}/client`,
    {
      list,
    },
    {
      headers: { Authorization: token },
    },
  )
}

export const getRefreshAPI = () => {
  return axios.get(`${process.env.REACT_APP_FETCH_URL}/manager/auth`, {
    headers: { Authorization: refreshToken },
  })
}
