import React, { useState } from 'react'
import { TextField, Button } from '@mui/material'
import { UseAdminLoginManager } from '@hooks/use-admin'

export default function Login() {
  const [id, setId] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const form = {
    id: id,
    password,
  }

  const { login } = UseAdminLoginManager()

  return (
    <main
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: 400,
          padding: '40px 30px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
      >
        <div style={{ margin: 20 }}>
          <h2 style={{ textAlign: 'center' }}>MOBUMTAX LOGIN</h2>
        </div>
        <div style={{ margin: 20 }}>
          <TextField
            fullWidth
            label='아이디'
            variant='outlined'
            required
            value={id}
            onChange={(e) => {
              setId(e.target.value)
            }}
          />
        </div>
        <div style={{ margin: 20 }}>
          <TextField
            fullWidth
            label='비밀번호'
            variant='outlined'
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </div>
        <div style={{ margin: 20 }}>
          <Button
            fullWidth
            variant='contained'
            style={{
              padding: '12px 0 ',
              fontSize: 18,
            }}
            onClick={() => {
              login.mutate(form)
            }}
          >
            로그인
          </Button>
        </div>
      </div>
    </main>
  )
}
